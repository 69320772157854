import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import SEO from "../components/seo"
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const BlogPost = styled.div`
  color: #666;
  margin-top: 30px;
  padding-bottom: 120px;
  border-bottom: 1px solid lightgrey;
`

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: darkslategrey;
`

const Published = styled.div`
  line-height:1rem;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #999;
`

const Content = styled.div`
  & a {
    color: darkslategrey;
  }
  & img {
    max-width: 100%;
    max-height: 400px;
  }
  & strong {
    font-weight: 700;
  }
`

const AudioLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
  justify-content: space-between;
  & > a {
    padding: 20px 0;
  }
`

const IndexPage = ({ data }) => {
  return (
    <div>
      <Layout active={'news'}>
        <SEO title="News" />
        {
          data.allFile.edges.map(({ node }) => {
            // console.log('node', node)
            const { childMarkdownRemark } = node // data.markdownRemark holds our post data
            const { frontmatter, rawMarkdownBody } = childMarkdownRemark
            return (
              <BlogPost>
                <Title>{frontmatter.title}</Title>
                <Published>
                  {/* {`* `} */}
                  {frontmatter.till != null ? (
                      `${frontmatter.from} - ${frontmatter.till} - ${frontmatter.location}`
                    ):(
                      `${frontmatter.from} - ${frontmatter.location}`
                    )
                }
                </Published>
                <Content>
                  {
                    frontmatter.title == "De podcast staat live!" ? (
                      <>
                        <p>Je kan de podcast "Het Lilian-eum" beluisteren via onderstaande links:</p>

                        <AudioLinks>
                          <a href="https://open.spotify.com/show/1KPjYvHMCL3zH2Vyz4scR4?si=e1e164893797423e" target='_blank'>
                            <StaticImage objectFit="contain" height='60' src='../images/listen-on-spotify-button.png' />
                          </a>
                          <a href="https://podcasts.apple.com/us/podcast/lilian-eum/id1615940688" target='_blank'>
                            <StaticImage objectFit="contain" height='60' src='../images/apple-podcasts.png' />
                          </a>
                        </AudioLinks>

                        <p>Meer lezen over de podcast ? <Link to='/podcast'>klik hier</Link></p>
                      </>
                    ) : (
                    <ReactMarkdown 
                      children={rawMarkdownBody}
                    />)
                  }
                </Content>
              </BlogPost>
            )
          })
        }
      </Layout>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "blog"}, extension: {eq: "md"}}, , sort: {fields: childMarkdownRemark___frontmatter___from, order: DESC}) {
      edges {
        node {
          childMarkdownRemark {
            rawMarkdownBody
            frontmatter {
              from(formatString: "MMMM DD, YYYY")
              till(formatString: "MMMM DD, YYYY")
              location
              title
            }
          }
        }
      }
    }
  }
`
